
import {defineComponent, nextTick, onMounted, reactive, ref, toRefs} from "vue";
import TablePagination from "@/components/TablePagination.vue";
import Dialog from "@/modules/dialog.vue";
import request from "@/utils/request";
import SearchForm from "@/modules/search-form.vue";
import {ElForm, ElMessage, ElMessageBox} from "element-plus";
import store from "@/store";
import dayjs from "dayjs";
import AvatarUploader from "@/components/AvatarUploader.vue";


export default defineComponent({
  components: {
    AvatarUploader,
    SearchForm,
    TablePagination,
    Dialog,
  },
  setup() {
    const eform = ref<null | typeof ElForm>();
    const table = ref<null | typeof TablePagination>();
    const table1 = ref<null | typeof TablePagination>();

    const arr: any[]=[];
    let selectRow:any = undefined;

    const f = {
      id:0,
      ructionType:'',
      ructionStatus : 'wait',
      verifyStatus:'wait',

      ructionStartTime:'',
      ructionEndTime:'',
      ructionOfferTime:'',
      isRuctionOffer:false,

      managementCompany:'',
      freightName:'',
      ructionStartPrice:0,
      minimumPriceReduction:1,
      price:0,
      bond:0,
      value:0,
      demurrageFees:0,
      loadingCapacity:0,
      freightType:'0',
      settlementType:'0',
      loadingDock:'',
      dischargeJetties:'',
      loadingTime:'',
      dischargeTime:'',
      transportationSafetyAndResponsibility:'',
      shipTypeAndServiceRequirements:'',
      file:''
    };
    const user={};
    let state = reactive({

      userTypeList:arr,
      typeList:arr,
      verifyList:arr,
      jiesuanList:arr,
      huowuList:arr,

      ructionStatusList:arr,
      companyList:arr,
      bidderList:arr,
      searchForm:{
        verifyStatus:'',
        ructionType:'',
        managementCompany:''
      },
      tableHeight:0,
      showEdit:false,
      showUser:false,
      showPersonList:false,
      isOffer:false,
      isSuper:true,
      loading:false,
      title:'',
      form: f,
      user
    });

    onMounted(() => {
      getWindowsHeight();
      getData();

      if(store.userType!='super'){
        state.isSuper=false;
      }
    });
    return {
      ...toRefs(state),
      searchClick,
      msgClick,
      closePaimai,
      editClick,
      deleteClick,
      addClick,
      confirmEdit,
      diableDate,
      lookPreson,
      choosePreson,
      chooseCLick,
      rowStyle,
      backUrl,
      userDetail,
      isOfferChange,
      ructionTypeChange,
      table,
      table1,
      eform,
      rules: {
        ructionStartTime:  { required: true, message: "开始时间不能为空", trigger: "blur" },
        ructionEndTime:  { required: true, message: "结束时间不能为空", trigger: "blur" },
        freightName:  { required: true, message: "货物名称不能为空", trigger: "blur" },
        ructionStartPrice:  { required: true, message: "拍卖价格不能为空", trigger: "blur" },
        ructionType:{ required: true, message: "拍卖类型不能为空", trigger: "blur"},
        verifyStatus:{ required: true, message: "审核状态不能为空", trigger: "blur"},
        managementCompany:{required: true, message: "管理公司不能为空", trigger: "blur"}
      },

    };


    function getData() {
      request({
        url: '/enum',
        method: "GET",
        params: {},
      }).then((res) => {
        const d = (res as Record<string, any>).content;

        state.verifyList = d.userVerifyMap;
        state.typeList = d.ructionTypeMap;
        state.jiesuanList = d.verifyMap;
        state.huowuList = d.verifyMap;

        state.userTypeList = d.userTypeMap;
        state.ructionStatusList = d.ructionStatusMap;

        state.companyList = d.managementCompanyMap;
      });
    }

    function diableDate(date:Date) {

      if(dayjs(date).isBefore(dayjs())){
        return true;
      }else{
        return false;
      }
    }
    function isOfferChange() {
      if(state.form.isRuctionOffer){
        state.form.ructionType = 'low_price';
      }
    }
    function ructionTypeChange() {
      if(state.form.ructionType != 'low_price'){
        state.form.isRuctionOffer = false;
      }
    }
    function getWindowsHeight(){
      state.tableHeight = document.body.clientHeight-50-50-30-80-50;
    }
    function searchClick(){
      table.value?.getList();
    }
    function userDetail(row:any){
      request({
        url: '/admin/query/user',
        method: "GET",
        params: {id:row.successfulBidderUserId},
      }).then((res) => {
        const d = res as Record<string, any>;

        state.showUser = true;
        state.form = d.content.user;
      });

    }
    function lookPreson(row:any) {
      state.bidderList = [];
      request({
        url: '/admin/query/offerList',
        method: "GET",
        params: {id:row.id,pageNum:1,pageSize:999},
      }).then(res=>{
        const d = res as Record<string, any>;
        state.bidderList = d.content.offerList;
        state.showPersonList = true;
        state.title = "查看出价人";
        state.isOffer = true;
      });
    }
    function choosePreson(row:any) {
      selectRow = row;

      state.bidderList = [];
      request({
        url: '/admin/query/bidderList',
        method: "GET",
        params: {id:row.id,pageNum:1,pageSize:999},
      }).then(res=>{
        const d = res as Record<string, any>;
        state.bidderList = d.content.bidderList;
        state.showPersonList = true;
        state.title = "查看中标人";
        state.isOffer = false;

        // nextTick(()=>{
        //   table1.value?.setCurrentRow('isSuccessfulBidder','1');
        // });
      });
    }

    function chooseCLick(row:any) {

      ElMessageBox.confirm("是否确定中标？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        request({
          url: '/admin/success/bidder',
          method: "POST",
          data: {id:row.ructionId,userId:row.bidderUserId},
        }).then(res=>{
          const d = res as Record<string, any>;
          ElMessage.success(d.text);
          table.value?.getList();

          state.bidderList = [];
          choosePreson(selectRow);
        });
      });
    }

    function closePaimai(row:any) {
      console.log(row);
      ElMessageBox.confirm("确定结束拍卖？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        request({
          url: '/admin/update/ructionEnd',
          method: "POST",
          data: {id:row.id},
        }).then(res=>{
          const d = res as Record<string, any>;
          ElMessage.success(d.text);
          table.value?.getList();
        });
      });
    }
    function backUrl(val:any){

      state.loading = false;
      state.form.file = val;
    }
    function addClick(){
      state.showEdit = true;
      state.form = JSON.parse(JSON.stringify(f));

      state.title = "新增";
    }
    function msgClick(row:any) {
      request({
        url: '/admin/send/sms',
        method: "GET",
        params: {id:row.id},
      }).then(res=>{
        const d = res as Record<string, any>;
        ElMessage.success(d.text);
      });
    }
    function editClick(row:any){
      request({
        url: '/admin/query/ruction',
        method: "GET",
        params: {id:row.id},
      }).then((res) => {
        const d = res as Record<string, any>;

        state.showEdit = true;
        state.form = d.content.data;


        state.title = "编辑";
      });
    }
    function deleteClick(row:any){

      request({
        url: "/api/admin/userDelete",
        method: "POST",
        data: row,
      }).then((res) => {
        const d = res as Record<string, any>;
        ElMessage.success(d.text);

        table.value?.getList();
      });
    }

    function confirmEdit(){
      eform.value?.validate((valid: boolean) => {
        if (valid) {

          if(!state.form.ructionStartPrice){
            state.form.ructionStartPrice = 0;
          }
          request({
            url: '/admin/save/ruction',
            method: "POST",
            data: JSON.stringify(state.form),
            headers:{
              'Content-Type':'application/json'
            }
          }).then((res) => {
            const d = res as Record<string, any>;
            ElMessage.success(d.text);

            state.showEdit = false;
            table.value?.getList();
          });
        }
      });
    }

    function rowStyle(row: any) {
      console.log(row);
      // return  {color:'white'};

      // if (row.rowIndex % 2 == 0) {
      //   return  {'background-color':'#04315c','color':'white','border-color':'#305a7d',};
      // } else {
      //   return  {'background-color':'#02132a','color':'white','border-color':'#305a7d',};
      // }
    }
  },
});
